import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { NotificationBreaking } from "../interfaces/notification/notification-breaking.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NotificationsService {
    private notification = new BehaviorSubject<NotificationBreaking | null>(null);

    notification$ = this.notification.asObservable();

    constructor(private http: HttpClient) {}

    getNotification() {
        return this.http.get<NotificationBreaking>(`${environment.API_UNA}${environment.API_NOTIFICATION}`).subscribe({
            next: (data) => {
                this.notification.next(data);
            },
            error: (error) => {
                console.log(error);
                this.notification.next(null);
            },
        });
    }
}
